$paginatorHeight: 32px;

.timesheet-report-container {

  .no-bottom-margin {
    margin-bottom: 0 !important;
  }

  .small-height {

    &.mat-mdc-paginator {
      height: $paginatorHeight;
      display: contents;

      .mat-mdc-paginator-outer-container,
      .mat-mdc-paginator-container {
        height: $paginatorHeight;
        min-height: $paginatorHeight;
        max-height: $paginatorHeight;
        margin: 3px 0;
      }

      .mat-mdc-form-field-flex,
      .mat-mdc-paginator-range-actions {
        height: $paginatorHeight;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }


  tbody {
    position: relative;
    top: 12px !important
  }
}

.small-height {
  .mat-mdc-option {
    font-size: 12px !important;
    min-height: 38px;
  }
}