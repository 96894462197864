// ------------------------------------------------------------------------------
// Media Queries
// ------------------------------------------------------------------------------
$breakpoint-xs: 599px;
$breakpoint-sm: 959px;
$breakpoint-md: 1279px;
$breakpoint-lg: 1919px;
$breakpoint-xl: 5000px;
// ------------------------------------------------------------------------------
// Border Radius
$border-radius-xs: 4px;
$border-radius-sm: 8px;
$border-radius-md: 16px;
$border-radius-lg: 24px;
$border-radius-xl: 32px;
// ------------------------------------------------------------------------------
// colors
// When adding a new color, you must also add it to: 1. The mixin below
// ------------------------------------------------------------------------------

$primary-lighter-color: #CCD3E6;
$primary-light-color: #334D99;
$primary-main-color: #002180;
$primary-dark-color: #00144d;
$primary-darker-color: #00071A;

$accent-lighter-color: #ccfaf1;
$accent-light-color: #99f5e3;
$accent-main-color: #00e5ba;
$accent-dark-color: #005C4A;
$accent-darker-color: #002e25;

$error-lighter-color: #ffd3d1;
$error-light-color: #E58784;
$error-main-color: #B71D18;
$error-dark-color: #971511;
$error-darker-color: #6f0814;

$black-color: #000000;
$white-color: #ffffff;

$gray-100: #F9FAFB;
$gray-200: #F4F6F8;
$gray-300: #dfe3e8;
$gray-400: #c4cdd5;
$gray-500: #919EAB;
$gray-600: #637381;
$gray-700: #454f5b;
$gray-800: #212b36;
$gray-900: #161c24;

$medium-grey-text: $gray-700;

// TODO: BD
$gray-border: #bbbbbb;
$golden-yellow-color: #f59c1a;
$light-yellow-color: #ffe6a7;


// TODO: BD and MK Got to here...
// Next: Add success, info and warning colors

$success-light-color: #d2f0d7;
$success-mild-color: #77ED8B;
$success-milder-color: #22C55E;
$success-dark-color: #2ebe45;
$success-darker-color: #3abc5d;
$success-darkest-color: #118D57;

$blue-mild-color: #006C9C;
$light-blue-color: #bcd5d7;
$light-sky-blue-color: #00B8D9;
$lighter-orange-color: #fef5e8;
$orange-mild-color: #FFD666;
$orange-color: #FFAB00;
$orange-dark-color: #B76E00;
$opaque-white-color: #ffffffc6;
$opaque-black-color: #00000078;
$purple-color: #7B61FF;

$progress-lighter-color: #fef5e8;
$progress-color: $golden-yellow-color;
$progress-darker-color: #f54f1a;


$employment-type-full-time: $light-blue-color;
$employment-type-part-time: #ffbaa7;
$employment-type-sub-contractor: #d7bdff;

$equipment-type-mobile: #7bdee5;
$equipment-type-static: #d1d1dd;

$content-bg-color: #f4f7fc;
$toggle-button-background-color: #d2d2d2;

$flyout-filter-backdrop-color: #5c5c5c6b;
$backdrop-color: #000000ed;

$tri-state-null-btn-selected-color: #dbdbdb;

$mat-body-1-font-size: 14px;
$mat-error-font-size: 10.5px;
$mat-disabled-border-color: #f0f0f0;
$mat-label-font-size: 11px;
$mat-ctrl-label-color: #00000099;
$mat-ctrl-border-color: #e0e0e0;
$mat-ctrl-hover-border-color: #212121;
$mat-error-letter-spacing: 1.5px;

$sub-item-list-bg-color: #99a6cc3d;
$aggregated-row-bg-color: #ebebeb;

$border-color: #dddddd;
$date-range-border-color: #e6e6e6;

$table-header-color: $gray-600;
$table-header-background-color: #f8f9f9;

$text-color-primary: #212B36;
$text-color-secondary: #637381;
$text-color-disabled: #919EAB;

$light-primary-text: $text-color-secondary;
$dark-primary-text: $text-color-primary;

$avatar-one: #b4eeb4;
$avatar-two: #ffb599;
$avatar-three: #ffc993;
$avatar-four: #feeca1;
$avatar-five: #9bf6ff;
$avatar-six: #e8eafa;
$avatar-seven: #ffbcde;

$active-color: $success-darker-color;
$active-background-color: $success-light-color;

$inactive-color: $text-color-primary;
$inactive-background-color: rgb(228, 228, 228);

$expired-color: $error-dark-color;
$expired-background-color: $error-lighter-color;

$compliant-color: $primary-light-color;
$compliant-background-color: $content-bg-color;

$non-compliant-color: $error-dark-color;
$non-compliant-background-color: $error-lighter-color;

$compliance-due-soon-color: $orange-dark-color;
$compliance-due-soon-background-color: rgba(255, 171, 0, 0.16);

$service-soon-color: $orange-dark-color;
$service-soon-background-color: rgba(255, 171, 0, 0.16);

$all-background-color: rgba(0, 33, 128, 0.16);

// ------------------------------------------------------------------------------
@mixin neo-colors {
  :root {
    --primary-lighter-color: #{$primary-lighter-color};
    --primary-light-color: #{$primary-light-color};
    --primary-main-color: #{$primary-main-color};
    --primary-dark-color: #{$primary-dark-color};
    --primary-darker-color: #{$primary-darker-color};

    --accent-lighter-color: #{$accent-lighter-color};
    --accent-light-color: #{$accent-light-color};
    --accent-main-color: #{$accent-main-color};
    --accent-dark-color: #{$accent-dark-color};
    --accent-darker-color: #{$accent-darker-color};

    --error-lighter-color: #{$error-lighter-color};
    --error-light-color: #{$error-light-color};
    --error-main-color: #{$error-main-color};
    --error-dark-color: #{$error-dark-color};
    --error-darker-color: #{$error-darker-color};

    --black-color: #{$black-color};
    --white-color: #{$white-color};

    --gray-100: #{$gray-100};
    --gray-200: #{$gray-200};
    --gray-300: #{$gray-300};
    --gray-400: #{$gray-400};
    --gray-500: #{$gray-500};
    --gray-600: #{$gray-600};
    --gray-700: #{$gray-700};
    --gray-800: #{$gray-800};
    --gray-900: #{$gray-900};

    --medium-grey-text: #{$medium-grey-text};
    --gray-border: #{$gray-border};
    --golden-yellow-color: #{$golden-yellow-color};
    --light-yellow-color: #{$light-yellow-color};

    --steel-blue-color: #{$primary-main-color};
    --blue-mild-color: #{$blue-mild-color};
    --light-blue-color: #{$light-blue-color};
    --light-sky-blue-color: #{$light-sky-blue-color};
    --lighter-orange-color: #{$lighter-orange-color};
    --orange-mild-color: #{$orange-mild-color};
    --orange-color: #{$orange-color};
    --orange-dark-color: #{$orange-dark-color};
    --opaque-white-color: #{$opaque-white-color};
    --opaque-black-color: #{$opaque-black-color};
    --purple-color: #{$purple-color};
    --progress-lighter-color: #{$progress-lighter-color};
    --progress-color: #{$progress-color};
    --progress-darker-color: #{$progress-darker-color};
    --success-light-color: #{$success-light-color};
    --success-mild-color: #{$success-mild-color};
    --success-milder-color: #{$success-milder-color};
    --success-dark-color: #{$success-dark-color};
    --success-darker-color: #{$success-darker-color};
    --success-darkest-color: #{$success-darkest-color};
    --employment-type-full-time: #{$employment-type-full-time};
    --employment-type-part-time: #{$employment-type-part-time};
    --employment-type-sub-contractor: #{$employment-type-sub-contractor};
    --equipment-type-mobile: #{$equipment-type-mobile};
    --equipment-type-static: #{$equipment-type-static};
    --content-bg-color: #{$content-bg-color};
    --toggle-button-background-color: #{$toggle-button-background-color};
    --flyout-filter-backdrop-color: #{$flyout-filter-backdrop-color};
    --backdrop-color: #{$backdrop-color};
    --tri-state-null-btn-selected-color: #{$tri-state-null-btn-selected-color};
    --mat-disabled-border-color: #{$mat-disabled-border-color};
    --mat-label-font-size: #{$mat-label-font-size};
    --mat-ctrl-label-color: #{$mat-ctrl-label-color};
    --mat-ctrl-border-color: #{$mat-ctrl-border-color};
    --mat-ctrl-hover-border-color: #{$mat-ctrl-hover-border-color};
    --mat-error-letter-spacing: #{$mat-error-letter-spacing};
    --sub-item-list-bg-color: #{$sub-item-list-bg-color};
    --aggregated-row-bg-color: #{$aggregated-row-bg-color};
    --border-color: #{$border-color};
    --date-range-border-color: #{$date-range-border-color};
    --table-header-color: #{$table-header-color};
    --table-header-background-color: #{$table-header-background-color};
    --text-color-primary: #{$text-color-primary};
    --text-color-secondary: #{$text-color-secondary};
    --text-color-disabled: #{$text-color-disabled};
    --light-primary-text: #{$light-primary-text};
    --dark-primary-text: #{$dark-primary-text};

    --avatar-one: #{$avatar-one};
    --avatar-two: #{$avatar-two};
    --avatar-three: #{$avatar-three};
    --avatar-four: #{$avatar-four};
    --avatar-five: #{$avatar-five};
    --avatar-six: #{$avatar-six};
    --avatar-seven: #{$avatar-seven};

    --active-color: #{$active-color};
    --active-background-color: #{$active-background-color};
    --inactive-color: #{$inactive-color};
    --inactive-background-color: #{$inactive-background-color};
    --expired-color: #{$expired-color};
    --expired-background-color: #{$expired-background-color};
    --compliant-color: #{$compliant-color};
    --compliant-background-color: #{$compliant-background-color};
    --non-compliant-color: #{$non-compliant-color};
    --non-compliant-background-color: #{$non-compliant-background-color};
    --compliance-due-soon-color: #{$compliance-due-soon-color};
    --compliance-due-soon-background-color: #{$compliance-due-soon-background-color};
    --service-soon-color: #{$service-soon-color};
    --service-soon-background-color: #{$service-soon-background-color};
    --all-background-color: #{$all-background-color};
  }
}
// ------------------------------------------------------------------------------

