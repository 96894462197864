@use '@angular/material' as mat;
@use "neo_scrollbar" as neoScrollbar;
@use "neo_sidebar_scrollbar" as neoSidebarScrollbar;
@use "variables" as neoVars;
@use "@angular/material/prebuilt-themes/indigo-pink";

@import "https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round";
@import url("https://fonts.googleapis.com/css?family=Inter:300,400,500");

@include mat.elevation-classes();
@include mat.app-background();

$font-config: mat.m2-define-typography-config($headline-1: mat.m2-define-typography-level(96px, 96px, 300, "inter"),
    $headline-2: mat.m2-define-typography-level(60px, 60px, 300, "inter"),
    $headline-3: mat.m2-define-typography-level(48px, 48px, 400, "inter"),
    $headline-4: mat.m2-define-typography-level(34px, 34px, 400, "inter"),
    $headline-5: mat.m2-define-typography-level(24px, 24px, 400, "inter"),
    $headline-6: mat.m2-define-typography-level(20px, 20px, 500, "inter"),
    $subtitle-1: mat.m2-define-typography-level(16px, 16px, 400, "inter"),
    $subtitle-2: mat.m2-define-typography-level(14px, 14px, 500, "inter"),
    $body-1: mat.m2-define-typography-level(16px, 16px, 400, "inter"),
    $body-2: mat.m2-define-typography-level(14px, 14px, 400, "inter"),
    $button: mat.m2-define-typography-level(14px, 14px, 500, "inter"),
    $caption: mat.m2-define-typography-level(12px, 12px, 400, "inter"),
    $overline: mat.m2-define-typography-level(10px, 10px, 400, "inter"));

@include mat.typography-hierarchy($font-config);

$neo-dark: (
  50: #ececee,
  100: #c5c6cb,
  200: #9ea1a9,
  300: #7d818c,
  400: #5c616f,
  500: #3c4252,
  600: #353a48,
  700: #2d323e,
  800: #262933,
  900: #1e2129,
  A100: #c5c6cb,
  A200: #9ea1a9,
  A400: #5c616f,
  A700: #2d323e,
  contrast: (50: neoVars.$dark-primary-text,
    100: neoVars.$dark-primary-text,
    200: neoVars.$dark-primary-text,
    300: neoVars.$light-primary-text,
    400: neoVars.$light-primary-text,
    500: neoVars.$light-primary-text,
    600: neoVars.$light-primary-text,
    700: neoVars.$light-primary-text,
    800: neoVars.$light-primary-text,
    900: neoVars.$light-primary-text,
    A100: neoVars.$dark-primary-text,
    A200: neoVars.$light-primary-text,
    A400: neoVars.$light-primary-text,
    A700: neoVars.$light-primary-text,
  ),
);

$neo-primary: (
  50: #E6E9F2,
  100: #CCD3E6,
  200: #99A6CC,
  300: #667AB3,
  400: #334D99,
  500: #002180,
  600: #001A66,
  700: #00144D,
  800: #000D33,
  900: #00071A,
  A100: #CCD3E6,
  A200: #99A6CC,
  A400: #334D99,
  A700: #00144D,
  contrast: (50: neoVars.$dark-primary-text,
    100: neoVars.$dark-primary-text,
    200: neoVars.$dark-primary-text,
    300: neoVars.$light-primary-text,
    400: neoVars.$light-primary-text,
    500: neoVars.$light-primary-text,
    600: neoVars.$light-primary-text,
    700: neoVars.$light-primary-text,
    800: neoVars.$light-primary-text,
    900: neoVars.$light-primary-text,
    A100: neoVars.$dark-primary-text,
    A200: neoVars.$light-primary-text,
    A400: neoVars.$light-primary-text,
    A700: neoVars.$light-primary-text,
  ),
);

$neo-secondary: (
  50: #E6FCF8,
  100: #CCFAF1,
  200: #99F5E3,
  300: #66EFD6,
  400: #33EAC8,
  500: #00E5BA,
  600: #00B795,
  700: #008970,
  800: #005C4A,
  900: #002E25,
  A100: #CCFAF1,
  A200: #99F5E3,
  A400: #33EAC8,
  A700: #008970,
  contrast: (50: neoVars.$dark-primary-text,
    100: neoVars.$dark-primary-text,
    200: neoVars.$dark-primary-text,
    300: neoVars.$light-primary-text,
    400: neoVars.$light-primary-text,
    500: neoVars.$light-primary-text,
    600: neoVars.$light-primary-text,
    700: neoVars.$light-primary-text,
    800: neoVars.$light-primary-text,
    900: neoVars.$light-primary-text,
    A100: neoVars.$dark-primary-text,
    A200: neoVars.$light-primary-text,
    A400: neoVars.$light-primary-text,
    A700: neoVars.$light-primary-text,
  ),
);
$default-primary-palette: mat.m2-define-palette($neo-primary, 400, 600, 700);
$default-accent-palette: mat.m2-define-palette($neo-secondary, 600, 400, 700);
$default-warn-palette: mat.m2-define-palette(mat.$m2-red-palette, 800, 400, 600);
$theme: mat.m2-define-light-theme((color: (primary: $default-primary-palette,
        accent: $default-accent-palette,
        warn: $default-warn-palette ),
      typography: $font-config,
    ));

// Emit theme-dependent styles for common features used across multiple components.
@include mat.all-component-themes($theme);
// @include mat.all-legacy-component-themes($theme);

// M3 material theme
// $theme: mat.define-theme();
// html {
//   @include mat.all-component-themes($theme);
//   @include mat.color-variants-backwards-compatibility($theme);
// }

// Material icons as classes
$icons: "arrow_drop_down";

@each $icon in $icons {
  .#{$icon}:after {
    content: $icon;
  }
}

// Mat overline doesnt exist in this version of angular typography
.mat-overline {
  font-size: 10px;
  font-weight: 600;
  font-family: 'Inter';
  letter-spacing: 0.25px;
  text-transform: uppercase;
}

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button,
.th {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
  // text-transform: uppercase;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.neo-toggle-button {

  .small {
    .mat-button-toggle-appearance-standard {
      .mat-button-toggle-label-content {
        padding: 5px !important;
      }
    }
  }
}

// .mat-button-toggle-appearance-standard {
//   .mat-button-toggle-label-content {
//     line-height: 36px;
//     padding: 0px 12px !important;
//   }
// }

.material-icons {
  font-size: 24px;
  font-family: "Material Icons Outlined", "Material Icons";

  .mat-badge-content {
    font-family: "Inter";
  }
}

.mat-body-1 {
  margin: 0px !important;
}

.mat-subtitle-1 {
  margin: 0px !important;
}

.mat-date-range-input-container {
  justify-content: space-around;
}

.multiline-tooltip {
  white-space: pre-line !important;
}

.mat-mdc-tooltip-panel .mat-mdc-tooltip-surface,
.neo-tooltip {
  text-overflow: unset !important;
  word-break: break-word !important;
  white-space: pre-line !important;
}

.mat-mdc-tooltip-panel {

  .mat-mdc-tooltip-surface {
    max-width: 250px !important;
    max-height: 70vh !important;
    font-size: 10px;
    overflow: auto;
  }
}

.dark-tooltip {
  background: neoVars.$text-color-primary !important;
  color: neoVars.$white-color !important;
  border-radius: 6px !important;

  // mdc support
  --mdc-plain-tooltip-container-color: neoVars.$black-color !important;
  --mdc-plain-tooltip-supporting-text-color: neoVars.$white-color !important;
}

.warn-tooltip {
  background: neoVars.$error-main-color !important;
  color: neoVars.$white-color !important;

  // mdc support
  --mdc-plain-tooltip-container-color: neoVars.$error-main-color !important;
  --mdc-plain-tooltip-supporting-text-color: neoVars.$white-color !important;
}

.light-tooltip {
  background: neoVars.$white-color !important;
  color: neoVars.$black-color !important;

  // mdc support
  --mdc-plain-tooltip-container-color: neoVars.$white-color !important;
  --mdc-plain-tooltip-supporting-text-color: neoVars.$black-color !important;
}

.primary-tooltip {
  background: neoVars.$primary-light-color !important;
  color: neoVars.$white-color !important;

  // mdc support
  --mdc-plain-tooltip-container-color: neoVars.$primary-light-color !important;
  --mdc-plain-tooltip-supporting-text-color: neoVars.$white-color !important;
}

.success-tooltip {
  background: neoVars.$success-darker-color !important;
  color: neoVars.$white-color !important;

  // mdc support
  --mdc-plain-tooltip-container-color: neoVars.$success-darker-color !important;
  --mdc-plain-tooltip-supporting-text-color: neoVars.$white-color !important;
}

// .smsGapDays {
//   .mat-checkbox-layout { // This style is no longer used
//     display: inline-flex;
//     white-space: normal;
//   }
// }

.select-all-container {
  .mdc-label {
    font-weight: 600;
  }
}

.mat-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-text-infix .mat-input-element[type=time] {
  height: 20px;
  display: flex;
}

.neo-time-ctrl {
  input[type="time"] {
    &::-webkit-calendar-picker-indicator {
      font-size: 0;
      min-height: 18px;
      max-height: 18px;
    }
  }

  .mat-mdc-form-field-text-suffix {
    display: block;
    position: absolute;
    top: 18px !important;
    right: 12px;

  }
}

.mat-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-text-infix {
  display: flex;
  flex-direction: column-reverse;
}

/** Temporary hack + fix for dropdown menus not appearing in the right place */
.mat-select-panel-wrap {
  position: relative;
  top: 30px;
}

/** Temporary hack + fix for dropdown menus not appearing in the right place */
.mat-select-panel-wrap .mat-select-panel {
  min-width: calc(100% + 12px) !important;
  transform: translateX(5px) !important
}

.neo-form-renderer-container {

  .mat-form-field {
    &.mat-focused {
      input {
        position: relative;
      }

      textarea {
        position: relative;
      }

      .mat-mdc-floating-label-wrapper {
        position: relative;
        display: block;
        margin-top: -1.8em;
        overflow: visible;

        .mat-mdc-floating-label {
          white-space: normal;
          position: relative;
        }
      }
    }
  }
}

.mat-checkbox-disabled {
  opacity: 0.5;
}

.disabled {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.mat-form-field-appearance-outline {
  .mat-form-field-wrapper {
    padding-bottom: unset;
  }

  .mat-mdc-form-field-subscript-wrapper {
    padding: 0 0.5em !important;
  }
}

.errored-color {
  color: neoVars.$error-main-color;
}

.error-text {
  width: 100%;
  min-height: inherit;
  line-height: 24px;
  display: flex;
  justify-content: left;
  align-items: center;
  color: neoVars.$error-main-color;
  font-style: italic;
}

.loading-spinner {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.15);
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.show-on-top {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  &.full-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .loader-text {
    color: neoVars.$primary-light-color;
    font-weight: 600;
  }
}

.no-overflow {

  .mdc-dialog__container,
  .mat-mdc-dialog-content,
  .mat-mdc-dialog-surface,
  .mdc-dialog__surface {
    overflow: unset !important;
  }
}

.neo-backdrop {
  background: neoVars.$backdrop-color;
}

.neo-media-viewer {
  height: 100vh !important;
  width: 100vw !important;

  .mat-mdc-dialog-container .mat-mdc-dialog-surface {
    background-color: neoVars.$backdrop-color;
  }
}

.show-panel-on-top {
  .ng-dropdown-panel {
    position: absolute;
    z-index: 99999 !important;

    @extend .neo-scrollbar;
  }
}

.datePicker-outer-container {
  display: flex;
  flex-direction: column;
  margin-top: -8px;

  .mat-error {
    font-size: neoVars.$mat-error-font-size;
    margin-left: 10px;
    margin-top: -10px;
  }

  .datePicker-inner-container {
    &.errored {
      @extend .errored-color;
      border-color: neoVars.$error-main-color;
      border: solid 2px;
      border-radius: 5px;
    }

    display: flex;
    border: solid 0.25px neoVars.$gray-500;
    padding-left: 10px;
    border-radius: 8px;

    .mat-date-range-input {
      max-width: 220px !important;

      .mat-date-range-input-start-wrapper,
      .mat-date-range-input-end-wrapper,
      input.mat-date-range-input-inner,
      input.mat-date-range-input-inner {
        max-width: 95px !important;
        min-width: 95px !important;

        .mat-date-range-input-inner {
          text-align: center;
          vertical-align: sub;
        }
      }
    }
  }
}

mat-drawer-container {
  .mat-drawer-inner-container {
    @extend .neo-sidemenu-scrollbar !optional;
  }

  @extend .neo-scrollbar !optional;
}

.ngx-timepicker {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 4px;

  &.ngx-timepicker--disabled {
    background: neoVars.$white-color !important;
    pointer-events: none;
  }

  .ngx-timepicker-control__arrows {
    top: -5px !important;
  }
}

.mat-progress-spinner.white-circle circle,
.mat-spinner.white-circle circle {
  stroke: neoVars.$white-color;
}

.header-button,
neo-button {
  .mat-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
  }
}

.email-composer-container {
  .mat-form-field {
    display: flex;
    flex-wrap: wrap;

    &.errored {
      .mat-form-field-outline {
        border-color: neoVars.$error-main-color;
        @extend .errored-color;
      }

      .mat-mdc-floating-label {
        color: neoVars.$error-main-color !important;
      }

      .mat-placeholder-required {
        .mat-form-field-required-marker {
          color: neoVars.$error-main-color !important;
        }
      }
    }

    .mat-form-field-wrapper {
      width: inherit;
    }

    .mat-chip-grid {
      @extend .neo-scrollbar;

      .mat-chip-grid-wrapper {
        max-height: 80px;
        overflow-y: auto;

        mat-chip.mat-chip {
          height: auto;
          line-height: 1.1em;
          line-break: anywhere;

          &.errored {
            color: neoVars.$error-main-color;
            box-shadow: inset 0 0 0 1px neoVars.$error-main-color,
              inset 0 0 0 1px neoVars.$error-main-color,
              inset 0 0 0 1px neoVars.$error-main-color,
              inset 0 0 0 1px neoVars.$error-main-color;
          }
        }
      }
    }

    .mat-error {
      margin-top: 15px;
    }
  }
}

.green {
  color: neoVars.$success-darker-color;

  svg {
    fill: neoVars.$success-darker-color;
  }
}

.red {
  color: neoVars.$error-main-color;

  svg {
    fill: neoVars.$error-main-color;
  }
}

.orange {
  color: neoVars.$golden-yellow-color;

  svg {
    fill: neoVars.$golden-yellow-color;
  }
}

.gray {
  color: neoVars.$medium-grey-text;

  svg {
    fill: neoVars.$medium-grey-text;
  }
}

.small-icon,
.small-icon svg {
  width: 18px !important;
  height: 18px !important;
}

.checkbox-container {
  padding-left: 16px;
  padding-right: 8px;

  .mat-mdc-checkbox {
    width: 100%;

    .mdc-form-field {
      width: 100%;
      display: flex;
      align-items: center;

      label {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        font-family: "inter";
      }
    }
  }
}

.mat-mdc-checkbox.mat-mdc-checkbox-checked .mdc-checkbox__background,
.mdc-checkbox__ripple,
.mdc-checkbox__checkmark {
  background-color: neoVars.$primary-light-color !important;
  border-color: neoVars.$primary-light-color !important;
  color: neoVars.$white-color !important;
}

.mat-button-toggle-group-appearance-standard {
  height: 52px;
  .mat-button-toggle-checked {
    background-color: rgba(neoVars.$primary-light-color, 0.32);
  }
}

mat-progress-bar {
  height: 6px !important;

  .mdc-linear-progress__bar-inner {
    border-top-width: 6px !important;
  }
}

.mdc-button {
  &:not(.mat-mdc-button-disabled) {
    &.mat-primary {
      background-color: neoVars.$primary-main-color !important;
      color: neoVars.$white-color !important;
    }

    &.mat-accent {
      background-color: neoVars.$accent-main-color !important;
      color: neoVars.$white-color !important;
    }

    &.mat-warn {
      background-color: neoVars.$error-main-color !important;
      color: neoVars.$white-color !important;
    }
  }

  .mat-mdc-button-touch-target {
    height: inherit !important;
  }
}

.mat-mdc-fab-base {
  &:not(.mat-mdc-button-disabled) {
    &.mat-primary {
      .mat-icon {
        color: neoVars.$white-color !important;
      }
    }

    &.mat-accent {
      .mat-icon {
        color: neoVars.$white-color !important;
      }
    }

    &.mat-warn {
      .mat-icon {
        color: neoVars.$white-color !important;
      }
    }
  }
}

.cdk-overlay-pane {
  &:has(.mat-mdc-select-panel.width-auto) {
    width: auto !important;
  }
}

.mat-mdc-snack-bar-container {
  .mat-mdc-snack-bar-label {
    padding: 0px !important;
  }
}

.mat-datepicker-content .mat-calendar {
  .mat-calendar-body-selected {
    background-color: neoVars.$primary-light-color !important;
    color: neoVars.$white-color !important;
  }
}

.dialog-panel-class {
  max-width: 90vw;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.cdk-overlay-pane,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 16px !important;
  }
}

.neo-confirmation-dialog-panel-class {
  max-width: 90vw;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.cdk-overlay-pane,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 16px !important;
  }
}

.resizable-dialog-panel-class {
  max-width: 90vw;
  max-height: 90vh;
  overflow: hidden;
  resize: both;
  display: flex;
  flex-direction: column;

  &.cdk-overlay-pane,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 16px !important;
  }
}

.shake-animation {
  animation: shake 0.35s ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }
}

.mat-mdc-form-field {
  &.shake-error-animation {
    .mat-mdc-form-field-subscript-wrapper {
      @extend .shake-animation;
    }
  }
}

.dense-input {
  height: 52px;
  min-height: 52px;
  max-height: 52px;
  // margin: 4px 0 4px 0;
  // padding: 2px 0 2px 0;
}
