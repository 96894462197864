@import "ej2-base/styles/material.scss";
@import "ej2-buttons/styles/material.scss";
@import "ej2-calendars/styles/material.scss";
@import "ej2-dropdowns/styles/material.scss";
@import "ej2-inputs/styles/material.scss";
@import "ej2-lists/styles/material.scss";
@import "ej2-popups/styles/material.scss";
@import "ej2-navigations/styles/material.scss";
@import "ej2-angular-schedule/styles/material.scss";
@import "ej2-buttons/styles/material.scss";
@import "ej2-popups/styles/material.scss";
@import "ej2-splitbuttons/styles/material.scss";
@import "neo_syncfusion";

@import "@angular/cdk/overlay-prebuilt.css";

@import "variables";
@import "neo_mat_theme";
@import "form";
@import "ng-select";
@import "neo_scrollbar";
@import "neo_sidebar_scrollbar";
@import "neo_sidemenu_scrollbar";
@import "footer_action_button";
@import "neo_flyout_filter";
@import "neo_table";
@import "neo_reporting";
@import "neo_form_renderer";
@import "neo_calendar";
@import "neo_calendar_v2";
@import "neo_typography";

html,
body {
  @extend .neo-scrollbar;

  height: 100%;
  margin: 0;
}

.work-perform-modal {
  mat-dialog-container {
    padding: 0;
    border-radius: 10px;
  }
}

.neo-alert-panel {
  padding: 0px !important;
  min-height: 0px !important;
  margin-top: 50px !important;
}

.custom-error {
  background-color: $warn-color;
  color: $white-color;
  overflow-wrap: anywhere;

  .mat-simple-snackbar-action button {
    color: $yellow-color;
  }
}

#reportContainer {
  height: 800px;
}

.no-data-found {
  width: 100%;
  display: flex;
  padding: 10px 0px;
  padding-top: 32px;
  column-gap: 5px;
  text-align: center;
  color: $gray-color;
  align-items: center;
  justify-content: center;
}

.position-fixed {
  position: fixed !important;
}
