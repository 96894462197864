@use "styling/variables" as neoVars;

.neo-form-renderer-container {
  @media screen and (max-width: neoVars.$breakpoint-sm) {
    .main-container {
      .fields-group {
        padding: 5px 5px 10px 5px !important;

        .neo-form-control {
          width: 100% !important;
        }
      }
    }
  }

  .main-container {
    width: 100%;

    .fields-group {
      display: flex;
      flex-wrap: wrap;
      margin-top: 12px;
      padding: 5px 10px 10px 10px;
      background: neoVars.$white-color;
      align-content: flex-start;
      row-gap: 16px;
      border-radius: 8px;

      &.without-label {
        padding-top: 10px !important;
      }

      .neo-label {
        width: 100% !important;
      }

      .neo-repeatable-section {
        width: 100%;
      }

      neo-checkbox {
        .text-section {
          .row {
            column-gap: 25px;
          }
        }
      }

      .neo-repeatable-section-form-control {
        margin: 0;
        min-height: 60px;
        height: fit-content;
        width: 100%;

        &.neo-readonly {
          min-height: unset !important;
          height: 100% !important;

          .document-section {
            margin-left: unset !important;
          }
        }

        .mat-mdc-form-field {
          width: 100%;
        }

        .mat-form-field {
          width: 100%;
        }

        &.neo-Photo {
          margin-top: 8px;

          .container {
            width: 100%;
          }

          .inner-container {
            width: 100%;
          }
        }

        &.neo-Signature {
          margin-top: 8px;
        }

        &.neo-Textarea {
          .mat-form-field {
            .mat-mdc-form-text-infix {
              height: auto;
            }
          }
        }

        &.neo-Dropdown {
          position: relative;
        }
      }

      .neo-form-control {
        width: 50%;
        padding-left: 5px;
        padding-right: 5px;

        .mat-mdc-form-field {
          width: 100%;
        }
      }

      .mat-form-field-wrapper {
        padding-bottom: unset !important;
      }

      .datepicker {
        .mat-mdc-form-field {
          .mat-mdc-form-text-infix {
            display: flex;
          }
        }
      }

      @media screen and (max-width: neoVars.$breakpoint-sm) {
        neo-Photo {
          .inner-container {
            min-width: 60px !important;
            max-width: unset !important;
            width: auto !important;

            .neo-repeatable-section-form-control {
              .document-section {
                max-width: 150px !important;
              }
            }
          }
        }
      }
    }
  }
}