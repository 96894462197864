@use '@angular/material' as mat;
@use "variables" as neoVars;

// typography
.neo-subtitle-2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: neoVars.$text-color-primary;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.stats {
  margin: 4px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 0.9em;
  font-weight: lighter;
  font-style: italic;
}

ul {
  margin-block-start: 4px;
  margin-block-end: 4px;
  padding-inline-start: 24px;

  li {
    line-height: 24px;
  }
}

// suffixes and prefixes have been set elsewhere in the application that moves them out of alignment!
::ng-deep .neo-v2-fix {

  .mat-form-field-appearance-outline .mat-mdc-form-field-text-prefix,
  .mat-form-field-appearance-outline .mat-mdc-form-field-text-suffix {
    top: .85em !important;
  }
}

// dialogs
.mdc-dialog__title {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

// tables
table {
  width: 100%;

  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type {
    padding-left: 16px !important;
  }

  th,
  td {
    padding: 2px;
    text-align: left;
  }
}

.table-sticky {
  width: 100%;

  .mat-cell,
  .mat-header-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
}

.table-header-column {
  font-size: normal;
  font-weight: 500;
  color: neoVars.$table-header-color;
  background-color: neoVars.$table-header-background-color;
}

.mat-mdc-row {

  &.table-row-selected {
    background-color: rgba(neoVars.$light-sky-blue-color, 0.08);
  }

  &.table-row-disabled {
    font-style: italic;
    color: neoVars.$medium-grey-text;
    background-color: whitesmoke;
  }
}

.table-footer-actions {
  margin: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: neoVars.$table-header-background-color;
}

.mat-header-row {
  position: sticky;
  top: 0;
  z-index: 2;
}

// Tabs
.tab-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0;
  padding: 4px 0;

  .tab {
    margin: 0;
    padding: 4px 0 4px 16px;
    min-height: 320px;
  }

  .tab-section {
    margin: 0;
    padding: 12px 0;
  }

  .mat-mdc-tab {
    color: auto;
  }

  .mat-mdc-tab.mdc-tab--active {
    color: neoVars.$text-color-primary;
  }
}

.property-label {
  color: neoVars.$text-color-secondary;
  padding-right: 4px;
}

.property-value {
  color: neoVars.$text-color-primary;
  padding-left: 4px;
}

// form-fields
.mat-form-field,
.mat-mdc-form-field {
  color: neoVars.$text-color-primary;
}

// icons
.mat-icon-button i,
.mat-icon-button .mat-icon {

  &.primary {
    color: neoVars.$text-color-primary !important;
  }

  &.secondary {
    color: neoVars.$text-color-secondary !important;
  }
}

// // General
// .pointer { cursor: pointer; }

// .align-left { text-align: left; }
// .align-center { text-align: center; }
// .align-right { text-align: right; }

// .m-2 { margin: 2px; }
// .mx-2 { margin-left: 2px; margin-right: 2px; }
// .my-2 { margin-top: 2px; margin-bottom: 2px; }
// .m-4 { margin: 4px; }
// .mx-4 { margin-left: 4px; margin-right: 4px; }
// .my-4 { margin-top: 4px; margin-bottom: 4px; }
// .p-2 { padding: 2px; }
// .px-2 { padding-left: 2px; padding-right: 2px; }
// .py-2 { padding-top: 2px; padding-bottom: 2px; }
// .p-4 { padding: 4px; }
// .px-4 { padding-left: 4px; padding-right: 4px; }
// .py-4 { padding-top: 4px; padding-bottom: 4px; }
// .p-8 { padding: 8px; }
// .px-8 { padding-left: 8px; padding-right: 8px; }
// .py-8 { padding-top: 8px; padding-bottom: 8px; }
// .p-12 { padding: 12px; }
// .px-12 { padding-left: 12px; padding-right: 12px; }
// .py-12 { padding-top: 12px; padding-bottom: 12px; }

// .w-spacer { width: 6px; }
// .w-20 { width: 20px; }
// .w-40 { width: 40px; }
// .w-60 { width: 60px; }
// .w-80 { width: 80px; }
// .w-100 { width: 100px; }
// .w-120 { width: 120px; }
// .w-140 { width: 140px; }
// .w-160 { width: 160px; }
// .w-180 { width: 180px; }
// .w-200 { width: 200px; }
// .w-300 { width: 300px; }
// .w-400 { width: 400px; }

// .h-spacer { height: 4px; }
// .h-12 { height: 12px; }
// .h-14 { height: 14px; }
// .h-16 { height: 16px; }
// .h-18 { height: 18px; }
// .h-20 { height: 20px; }
// .h-40 { height: 40px; }
// .h-60 { height: 60px; }
// .h-80 { height: 80px; }
// .h-100 { height: 100px; }
// .h-120 { height: 120px; }
// .h-140 { height: 140px; }
// .h-160 { height: 160px; }
// .h-180 { height: 180px; }
// .h-200 { height: 200px; }
// .h-300 { height: 300px; }
// .h-400 { height: 400px; }

// Calendar specific styles
.filter-menu,
.sort-by-menu {

  .mat-menu-content,
  .mat-mdc-menu-content {
    padding: 0;

    .filter-menu-container,
    .sort-by-menu-container {
      padding: 5px;

      .filter-header,
      .filter-projects,
      .filter-mainLevels,
      .filter-subLevels,
      .filter-logSheetStatus,
      .sort-by-header,
      .sort-by-opt-group {
        width: calc(100% - 16px);
        border-bottom: 1px solid rgba(neoVars.$text-color-disabled, 0.2) !important;
        padding: 10px 8px;
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      .filter-projects {
        .toggle-container {
          display: flex;
          align-items: center;
          gap: 7px;

          .label-text {
            line-height: 22px;
            font-size: 14px;
          }

          .mat-slide-toggle {
            .mat-slide-toggle-bar {
              width: 25px !important;
              height: 16px !important;
              background-color: rgba(neoVars.$text-color-disabled, 0.48) !important;

              .mat-slide-toggle-thumb-container {
                top: 2px !important;
                left: 2px !important;

                .mat-slide-toggle-thumb {
                  height: 11px !important;
                  width: 11px !important;
                }
              }
            }
          }

          .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
            transform: translate3d(9px, 0, 0) !important;
          }
        }
      }

      .filter-mainLevels,
      .filter-subLevels {
        .mat-form-field-wrapper {
          padding: 0 !important;

          .mat-mdc-form-field-flex {
            border: 1px solid rgba(neoVars.$text-color-disabled, 0.2) !important;
            padding: 0 14px !important;
            border-radius: 8px !important;
            height: 31px !important;
            background-color: neoVars.$white-color;
          }

          .mdc-line-ripple {
            display: none !important;
          }

          .mat-mdc-form-text-infix {
            padding: 0 !important;
            border: none !important;
            margin: auto !important;

            .mat-select-trigger {
              height: 100%;
              display: flex;
              align-items: center;

              .mat-mdc-select-arrow-wrapper {
                height: 100% !important;
              }
            }
          }
        }
      }

      .filter-logSheetStatus {

        .filter-select {
          display: flex;
          flex-direction: column;
        }

        .mat-mdc-checkbox {

          .mat-form-field,
          .mdc-form-field,
          .mdc-label {
            width: 100%;
          }
        }
      }

    }
  }
}

.neo-calendar {
  --mat-form-field-container-vertical-padding: 12px;

  .action-btn {
    cursor: pointer;

    &:hover {
      background-color: neoVars.$gray-200;
      transform: scale(1.05);
      border-radius: 4px;
    }
  }
}

.mat-mdc-button-base {
  &.cozy-button {
    padding: 8px 16px;
    font-weight: 500;
    border: none;

    .count-badge {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      min-width: 20px;
      padding: 2px;
      border-radius: 50%;
      background-color: neoVars.$accent-dark-color;
      color: neoVars.$white-color;
    }

    &.mat-mdc-button-disabled {
      .mat-icon {
        opacity: 0.5;
      }
    }
  }

  &.primary-light {
    background: rgba(neoVars.$primary-light-color, 0.08);
    color: neoVars.$primary-dark-color;
  }

  &.accent-light {
    background: rgba(neoVars.$accent-main-color, 0.08);
    color: neoVars.$accent-darker-color;
  }

  &.warn-light {
    background: rgba(neoVars.$error-main-color, 0.08);
    color: neoVars.$error-main-color;
  }
}

.neo-calendar-confirmation-dialog {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 16px !important;
  }

  .mdc-dialog__container,
  .mat-mdc-dialog-container {
    .checkbox-container {
      .mat-select {
        padding: 0 5px;
        font-weight: 500;
        width: fit-content;
      }

      &.error {
        .mat-select-value-text {
          color: neoVars.$error-main-color;
        }
      }

      &.warn {
        .mat-select-value-text {
          color: neoVars.$orange-dark-color;
        }
      }
    }
  }
}

.resource-availability-panel {

  .staff-available-wrapper,
  .staff-allocated-wrapper,
  .staff-on-leave-wrapper,
  .equipments-available-wrapper {

    @include mat.form-field-overrides((outlined-outline-width: 0,
        outlined-focus-outline-width: 0,
        container-text-size: 14px,
      ));

    mat-form-field {
      width: 100%;

      .mat-mdc-form-field-flex {
        background-color: rgba(neoVars.$gray-500, 0.08) !important;
        margin: 0 0 10px 0 !important;
        height: 50px;

        .mat-icon {
          height: 18px;
          width: 18px;
        }

        .mat-mdc-form-field-infix {
          display: flex !important;
          align-items: center !important;
        }
      }
    }

    .mat-expansion-panel {
      border-radius: 8px 8px 0 0;

      .mat-expansion-panel-header {
        background-color: neoVars.$gray-200;
        border-color: rgba(neoVars.$gray-500, 0.2);
        height: 56px;
        padding: 0 16px;
      }

      .mat-expansion-panel-body {
        padding: 0;
      }
    }
  }
}

.valid-drop-target {
  border-radius: 4px;
  z-index: 999;

  &.list-item {
    box-shadow: inset 0 2px 0 0 neoVars.$purple-color, inset 0 -2px 0 0 neoVars.$purple-color, inset 2px 0 0 0 neoVars.$purple-color, inset -2px 0 0 0 neoVars.$purple-color;
  }

  &.cdk-virtual-scroll-viewport {
    border-bottom: 4px solid neoVars.$purple-color !important;

    .day-placeholder {
      border-top: 4px solid neoVars.$purple-color !important;
      border-radius: 4px;
      z-index: 999;
    }
  }
}

.locked-drop-target {
  &.cdk-virtual-scroll-viewport {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    &::before {
      content: '';
      display: inline-block;
      background: url('../assets/calendar-v2/lock_red.svg') no-repeat center center;
      width: 20px;
      height: 20px;
      opacity: 0.5;
      position: relative;
      margin-top: 2px;
      z-index: 2;
    }

    &::after {
      content: 'Locked Day';
      color: neoVars.$error-main-color;
      font-weight: bold;
      margin-top: 6px;
      opacity: 0.5;
      position: relative;
      z-index: 2;
    }
  }
}

mat-icon.text-primary {
  svg>path {
    fill: neoVars.$text-color-primary !important;
  }
}

.transparent-scrollbar {
  overflow-y: scroll;

  &::-webkit-scrollbar,
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-top: 1px solid rgba(neoVars.$text-color-disabled, 0.2);
    border-bottom: 2px solid rgba(neoVars.$text-color-disabled, 0.2);
    border-radius: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}

 // TODO: legacy-calendar-deprecation-banner - Remove this class once the banner is removed
.legacy-calendar-deprecation-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: calc(100% + 12px);
  min-height: 48px;
  background-color: neoVars.$primary-main-color;
  color: neoVars.$white-color;
  position: relative;
  margin-left: -6px;

  a {
    color: neoVars.$white-color;
    text-decoration: underline;

    &:hover {
      opacity: 0.9;
    }
  }

  mat-icon {
    svg {
      fill: neoVars.$white-color;
    }
  }
}

.booking-info-dialog {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.cdk-overlay-pane,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 8px !important;
  }
}

.mat-mdc-form-field-has-icon-prefix .mat-mdc-form-field-flex {
  padding-left: 16px !important;
}
