@import './variables';

// suffixes and prefixes have been set elsewhere in the application that moves them out of alignment!
::ng-deep .neo-v2-fix {
  .mat-form-field-appearance-outline .mat-form-field-prefix,
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: .85em !important;
  }
}

// dialogs
.mdc-dialog__title {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dialog-panel-class {
  max-width: 90vw;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  &.cdk-overlay-pane,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 16px !important;
  }
}

.neo-confirmation-dialog-panel-class {
  max-width: 90vw;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  &.cdk-overlay-pane,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 16px !important;
  }
}

.resizable-dialog-panel-class {
  max-width: 90vw;
  max-height: 90vh;
  overflow: hidden;
  resize: both;
  display: flex;
  flex-direction: column;

  &.cdk-overlay-pane,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 16px !important;
  }
}
// tables
table {
  width: 100%;

  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type {
    padding-left: 16px !important;
  }

  th,
  td {
    padding: 2px;
    text-align: left;
  }
}

.table-sticky {
  width: 100%;

  .mat-cell,
  .mat-header-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
}

.table-header-column {
  font-size: normal;
  font-weight: 500;
  color: $table-header-color;
  background-color: $table-header-background-color;
}

.table-row-selected {
  background-color: rgba($light-sky-blue-color, 0.08);
}

.table-row-disabled {
  font-style: italic;
  color: $medium-grey-text;
  background-color: whitesmoke
}

.table-footer-actions {
  margin: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: $table-header-background-color;
}

.mat-header-row {
  position: sticky;
  top: 0;
  z-index: 2;
}
// Tabs
.tab-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0;
  padding: 4px 0;

  .tab {
    margin: 0;
    padding: 4px 0 4px 16px;
    min-height: 320px;
  }

  .tab-section {
    margin: 0;
    padding: 12px 0;
  }

  .mat-tab-label {
    color: auto;
  }

  .mat-tab-label.mat-tab-label-active {
    color: $text-color-primary;
  }
}


// Overlays
.overlay-transparent-backdrop {
  background-color: transparent !important;
}

// form-fields
.mat-form-field {
  color: $text-color-primary;
}

// icons
.mat-icon-button i,
.mat-icon-button .mat-icon {

  &.primary {
    color: $text-color-primary !important;
  }

  &.secondary {
    color: $text-color-secondary !important;
  }
}

