@import "./variables";

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.neo-calendar {

  .action-btn {
    cursor: pointer;
    padding: 1px;

    &:hover {
      background-color: $lighter-gray-color;
      transform: scale(1.05);
      border-radius: 4px;
    }
  }
}

.mat-button-base {
  &.cozy-button {
    padding: 8px;
    font-weight: 500;
    border: none;
  }

  &.primary-light {
    background: rgba($primary-color, 0.08);
    color: $primary-darker-color;
  }

  &.accent-light {
    background: rgba($accent-color, 0.08);
    color: $accent-darker-color;
  }

  &.warn-light {
    background: rgba($warn-color, 0.08);
    color: $warn-darker-color;
  }
}

.neo-calendar-confirmation-dialog {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 16px !important;
  }

  .mat-dialog-container,
  .mat-mdc-dialog-container {
    .checkbox-container {
      .mat-select {
        padding: 0 5px;
        font-weight: 500;
        width: fit-content;
      }

      &.error {
        .mat-select-value-text {
          color: $warn-dark-color;
        }
      }

      &.warn {
        .mat-select-value-text {
          color: $orange-dark-color;
        }
      }
    }
  }
}

.resource-availability-panel {

  .staff-available-wrapper,
  .staff-allocated-wrapper,
  .staff-on-leave-wrapper,
  .equipments-available-wrapper {
    mat-form-field {
      width: 100%;

      .mat-form-field-wrapper {
        background-color: rgba($gray-muted-color, 0.08) !important;
        margin: 0 0 10px 0 !important;

        .mat-form-field-flex {
          gap: 10px !important;
          height: 53px;

          .mat-form-field-infix {
            padding: 0px;
            border: 0;
            margin: auto;
          }

          .mat-form-field-prefix,
          .mat-form-field-suffix {
            margin: auto;
            top: 0;

            .mat-icon.svg-icon {
              width: 18px !important;
              height: 18px !important;
              margin-top: -4px;
            }
          }
        }
      }
    }

    .mat-expansion-panel {
      border-radius: 8px 8px 0 0;

      .mat-expansion-panel-header {
        background-color: $gray-lightest-color;
        border-color: rgba($gray-muted-color, 0.2);
        height: 56px;
        padding: 0 16px;
      }

      .mat-expansion-panel-body {
        padding: 0;
      }
    }
  }
}

.filter-menu {

  .mat-menu-content,
  .mat-mdc-menu-content {
    padding: 0;

    .filter-menu-container {

      .filter-header,
      .filter-projects,
      .filter-mainLevels,
      .filter-subLevels {
        width: 100%;
        border-bottom: 1px solid rgba($text-color-disabled, 0.2) !important;
        padding: 10px 8px;
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      .filter-projects {
        .toggle-container {
          display: flex;
          align-items: center;
          gap: 7px;

          .label-text {
            line-height: 22px;
            font-size: 14px;
          }

          .mat-slide-toggle {
            .mat-slide-toggle-bar {
              width: 25px !important;
              height: 16px !important;
              background-color: rgba($text-color-disabled, 0.48) !important;

              .mat-slide-toggle-thumb-container {
                top: 2px !important;
                left: 2px !important;

                .mat-slide-toggle-thumb {
                  height: 11px !important;
                  width: 11px !important;
                }
              }
            }
          }

          .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
            transform: translate3d(9px, 0, 0) !important;
          }
        }
      }

      .filter-mainLevels,
      .filter-subLevels {
        .mat-form-field-wrapper {
          padding: 0 !important;

          .mat-form-field-flex {
            border: 1px solid rgba($text-color-disabled, 0.2) !important;
            padding: 0 14px !important;
            border-radius: 8px !important;
            height: 31px !important;
            background-color: $white-color;
          }

          .mat-form-field-underline {
            display: none !important;
          }

          .mat-form-field-infix {
            padding: 0 !important;
            border: none !important;
            margin: auto !important;

            .mat-select-trigger {
              height: 100%;
              display: flex;
              align-items: center;

              .mat-select-arrow-wrapper {
                height: 100% !important;
              }
            }
          }
        }
      }

    }
  }
}

.valid-drop-target {
  border-radius: 4px;
  z-index: 999;

  &.list-item {
    box-shadow: inset 0 2px 0 0 $purple-color, inset 0 -2px 0 0 $purple-color, inset 2px 0 0 0 $purple-color, inset -2px 0 0 0 $purple-color;
  }

  &.cdk-virtual-scroll-viewport {
    border-bottom: 4px solid $purple-color !important;

    .day-placeholder {
      border-top: 4px solid $purple-color !important;
      border-radius: 4px;
      z-index: 999;
    }
  }
}

.locked-drop-target {
  &.cdk-virtual-scroll-viewport {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    &::before {
      content: '';
      display: inline-block;
      background: url('../assets/calendar-v2/lock_red.svg') no-repeat center center;
      width: 20px;
      height: 20px;
      opacity: 0.5;
      position: relative;
      margin-top: 2px;
      z-index: 2;
    }

    &::after {
      content: 'Locked Day';
      color: $warn-color;
      font-weight: bold;
      margin-top: 6px;
      opacity: 0.5;
      position: relative;
      z-index: 2;
    }
  }
}

mat-icon.text-primary {
  svg>path {
    fill: $text-color-primary !important;
  }
}

.transparent-scrollbar {
  overflow-y: scroll;

  &::-webkit-scrollbar,
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-top: 1px solid rgba($text-color-disabled, 0.2);
    border-bottom: 2px solid rgba($text-color-disabled, 0.2);
    border-radius: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}

.legacy-calendar-deprecation-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: calc(100% + 12px);
  min-height: 48px;
  background-color: $primary-dark-color;
  color: $white-color;
  position: relative;
  margin-left: -6px;

  a {
    color: $white-color;
    text-decoration: underline;

    &:hover {
      opacity: 0.9;
    }
  }

  mat-icon {
    svg {
      fill: $white-color;
    }
  }
}

.booking-info-dialog {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.cdk-overlay-pane,
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 8px !important;
  }
}
