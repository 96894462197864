.footer-action-buttons-container {
  width: 100%;
  display: flex;
  justify-content: flex-end !important;
  margin-top: 24px;
}

.footer-action-buttons-container neo-button {
  padding: 0px 4px 0px 4px;
}
