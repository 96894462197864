@mixin tag-container {
  display: flex;
  column-gap: 3px;
  align-items: center;
  height: inherit;
}

@mixin tag {
  padding: 4px 5px;
  width: fit-content;
  border-radius: 10px;
  white-space: nowrap;
  font-size: 12px;
}

@mixin center {
  text-align: center;
  display: flex;
  justify-content: center;

  &.mat-header-cell {
    margin-left: 10px;
  }

  .mat-sort-header-content {
    text-align: center;
  }
}

@mixin text-align-center {
  text-align: center;

  .mat-sort-header-container {
    display: flex;
    justify-content: center;

    &:not(.mat-sort-header-sorted) {
      padding-left: 18px;
    }
  }
}
