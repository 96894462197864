ng-select.ng-select-multiple {
  max-height: 51px;
  div.ng-has-value {
    padding-top: 5px;
    padding-bottom: 5px;
    div.ng-value-container {
      mat-chip.mat-chip {
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 5px;
      }
    }
  }
}
