@use "variables" as neoVars;

.mat-drawer-content {

  /* scrollbar width */
  &::-webkit-scrollbar,
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* scrollbar Track */
  &::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px neoVars.$gray-500;
    border-radius: 10px;
  }

  /* scrollbar Handle */
  &::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb {
    background: rgba(neoVars.$text-color-disabled, 0.75) !important;
    border-radius: 10px;
  }

  /* scrollbar Handle on hover */
  &::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb:hover {
    background: neoVars.$text-color-disabled !important;
  }
}
