@use "styling/variables" as neoVars;
@use '@angular/material' as mat;

html,
body {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: neoVars.$text-color-primary;
}

h1 {
  font-size: 64px;
  font-family: Inter, sans-serif;
  font-weight: 800;
  line-height: 80px;
}

h2 {
  font-size: 48px;
  font-family: Inter, sans-serif;
  font-weight: 800;
  line-height: 64px;
}

h3 {
  font-size: 32px;
  font-family: Inter, sans-serif;
  font-weight: 800;
  line-height: 48px;
}

h4 {
  font-size: 24px;
  font-family: Inter, sans-serif;
  font-weight: 800;
  line-height: 36px;
}

h5 {
  font-size: 20px;
  font-family: Inter, sans-serif;
  font-weight: 800;
  line-height: 30px;
}

h6 {
  font-size: 18px;
  font-family: Inter, sans-serif;
  font-weight: 800;
  line-height: 28px;
}

.subtitle1 {
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  line-height: 24px;
}

.subtitle2 {
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  line-height: 22px;
}

.body1 {
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 24px;
}

.body2 {
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 22px;
}

.caption {
  font-size: 12px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 18px;
}

.overline {
  font-size: 12px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  line-height: 18px;
  text-decoration: uppercase;
}

@media (max-width: 600px) {
  h1 {
    font-size: 40px;
    font-family: Inter, sans-serif;
    font-weight: 800;
    line-height: 50px;
  }

  h2 {
    font-size: 32px;
    font-family: Inter, sans-serif;
    font-weight: 800;
    line-height: 42px;
  }

  h3 {
    font-size: 24px;
    font-family: Inter, sans-serif;
    font-weight: 800;
    line-height: 36px;
  }

  h4 {
    font-size: 20px;
    font-family: Inter, sans-serif;
    font-weight: 800;
    line-height: 30px;
  }

  h5 {
    font-size: 18px;
    font-family: Inter, sans-serif;
    font-weight: 800;
    line-height: 27px;
  }

  h6 {
    font-size: 17px;
    font-family: Inter, sans-serif;
    font-weight: 800;
    line-height: 26px;
  }

}


// TODO: BD: Finish these once they are done.

.hint, // remove other hints
.neo-caption {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}


// Include in <p>
.neo-body-1 {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.neo-body-2 {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

p {
  margin: 0 0 1rem; // Set margin for paragraphs
}

ul,
ol {
  margin: 0 0 1rem; // Bottom margin for unordered and ordered lists
  padding-left: 20px; // Indent lists
}

li {
  margin: 0.5rem 0; // Set margin between list items
}

// .mat-button {
//   font-weight: 500; // Set button font weight
// }

.token {
  background-color: rgba(neoVars.$light-sky-blue-color, 0.16);
  color: neoVars.$blue-mild-color;
  border-radius: 8px;
  padding: 5px;
  font-size: 12px;
}

.stats {
  margin: 4px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 0.9em;
  font-weight: lighter;
  font-style: italic;
}

ul {
  margin-block-start: 4px;
  margin-block-end: 4px;
  padding-inline-start: 24px;

  li {
    line-height: 24px;
  }
}



// Overlays
.overlay-white-backdrop {
  background-color: neoVars.$white-color !important;
}

.overlay-transparent-backdrop {
  background-color: transparent !important;
}

.overlay-panel-container {
  width: auto;
  height: auto;
  max-height: 300px;
  overflow-y: auto;
  margin-top: 2px;
  padding: 4px;
  background-color: neoVars.$white-color;
  border: 1px solid neoVars.$border-color;
  border-radius: 6px;
  z-index: 1000;

  &.raised-8 {
    margin-top: -8px;
  }

  &.raised-16 {
    margin-top: -16px;
  }
}

// panels
.panel-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 24px;
  padding: 24px;

  background-color: neoVars.$white-color;
  // if other backgounds are required then add them as classes here

  &.spaced {
    margin: 48px;
    padding: 48px;
  }

  &.dense {
    margin: 12px;
    padding: 12px;
  }

  &.bordered {
    padding: 24px;
    border-radius: neoVars.$border-radius-md;
    border: 1px solid neoVars.$primary-lighter-color;
  }
}

.panel-section {
  margin: 4px 0;
  padding: 4px 0;

  &.spaced {
    margin: 24px;
    padding: 24px;
  }

  &.dense {
    margin: 8px;
    padding: 8px;
  }

  &.bordered {
    padding: 16px;
    border-radius: neoVars.$border-radius-md;
    border: 1px solid neoVars.$primary-lighter-color;
  }
}

.panel-section-divider {
  margin: 8px 0;
  padding: 0;
  border-bottom: 1px solid neoVars.$primary-lighter-color;
}

.panel-list {
  margin: 4px 0;
  padding: 4px 0;

  &.spaced {
    margin: 24px;
    padding: 24px;
  }

  &.dense {
    margin: 8px;
    padding: 8px;
  }

  &.bordered {
    padding: 16px;
    border-radius: neoVars.$border-radius-md;
    border: 1px solid neoVars.$primary-lighter-color;
  }

  .panel-list-item {
    margin: 0;
    padding: 4px 0;
    line-height: 24px;

    &:not(:last-child) {
      border-bottom: 1px solid neoVars.$primary-lighter-color;
    }

    // Add edit styles here as required
    // &.edit {}
  }
}

// Status panel for validation etc
.neo-status-panel {

  padding: 16px;
  border-radius: neoVars.$border-radius-md;

  &.success {
    color: neoVars.$primary-main-color;
    background-color: rgba(neoVars.$success-milder-color, 0.16);
    border: 1px solid rgba(neoVars.$success-milder-color, 0.32);
  }

  &.info {
    color: neoVars.$primary-main-color;
    background-color: rgba(neoVars.$light-sky-blue-color, 0.16);
    border: 1px solid rgba(neoVars.$light-sky-blue-color, 0.32);
  }

  &.warn {
    color: neoVars.$error-main-color;
    background-color: rgba(neoVars.$error-main-color, 0.16);
    border: 1px solid rgba(neoVars.$error-dark-color, 0.32);
  }

  &.error {
    color: neoVars.$error-dark-color;
    background-color: rgba(neoVars.$error-darker-color, 0.16);
    border: 1px solid rgba(neoVars.$error-darker-color, 0.32);
  }

  &.sm {
    padding: 4px;
    border-radius: neoVars.$border-radius-sm;
  }

  span {
    margin: 4px 16px 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mat-icon {
    margin: 4px 16px 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
  }

}

// Toast panel
.neo-toast-panel {
  padding: 0px !important;
  min-height: 0px !important;
  margin-top: 40px !important;
  border-radius: 8px;
  background-color: neoVars.$white-color;
}

.mat-snack-bar-container {
  border-radius: 8px;
}

// properties - to be used in any settings/details/etc section
.property-label {
  color: neoVars.$text-color-secondary;
  padding-right: 4px;
}

.property-value {
  color: neoVars.$text-color-primary;
  padding-left: 4px;
}

// Chips
// Customize for the entire app
// Ref: https://material.angular.io/components/chips/styling
:root {
  @include mat.chips-overrides((
    label-text-color: neoVars.$text-color-primary,
    label-text-size: 12px,
    label-text-weight: 700,
  ));
}

// chips

@mixin neo-chip {
  border-radius: 8px !important;
  height: 2.7vh;
  margin: 2px;
  align-items: center;
  justify-content: space-between;

  // from start time
  // background-color: rgba(neoVars.$light-sky-blue-color, 0.16);
  // color: neoVars.$blue-mild-color;

  span,
  .chip-label {
    font-size: 12px;
    font-weight: 700;
    position: relative;
    &.raise-10 {
      top: -10px;
    }
  }

  .chip-button {
    padding: 2px;
    margin-left: auto;
  }

  ::ng-deep .mat-mdc-chip-action-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.neo-cell-chip {
  @include neo-chip;
}

// Status chips
.neo-status-chip {
  @include neo-chip;
}

// common styles
.status-active {
  background-color: neoVars.$active-background-color !important;
  span {
    color: neoVars.$active-color;
  }

  // add overrides here as required for different contexts
  // if the staff active status is required to be different from the default then complete the following
  // then add the contect to the html template: class="active staff"
  // &.staff {
  //   background-color: rgba(0, 128, 0, 0.16) !important;
  //   span {
  //     color: neoVars.$green-dark-color;
  //   }
  // }

}
.status-inactive {
  background-color: neoVars.$inactive-background-color !important;
  span {
    color: neoVars.$inactive-color;
  }
}

.status-expired {
  background-color: neoVars.$expired-background-color !important;
  span {
    color: neoVars.$expired-color;
  }
}

.status-non-compliant {
  background-color: neoVars.$non-compliant-background-color !important;
  span {
    color: neoVars.$non-compliant-color !important;
  }
}
.status-compliance-due-soon {
  background-color: neoVars.$compliance-due-soon-background-color !important;
  span {
    color: neoVars.$compliance-due-soon-color !important;
  }
}
.service-soon {
    background-color: neoVars.$service-soon-background-color !important;
    span {
      color: neoVars.$service-soon-color;
    }
  }
.employment-type-full-time {
  background-color: neoVars.$employment-type-full-time !important;
}
.employment-type-part-time {
  background-color: neoVars.$employment-type-part-time !important;
}
.employment-type-sub-contractor {
  background-color: neoVars.$employment-type-sub-contractor !important;
}
.filter-all {
  background-color: neoVars.$all-background-color !important;
}

.skill-tag {
  background: rgba(145, 158, 171, 0.16);
  span {
    color: neoVars.$text-color-secondary;
  }
}


/* prettier-ignore-start */

// General
.pointer { cursor: pointer; }

.align-left { text-align: left; }
.align-center { text-align: center; }
.align-right { text-align: right; }

// margin helpers
.m-2 { margin: 2px; }
.mx-2 { margin-left: 2px; margin-right: 2px; }
.my-2 { margin-top: 2px; margin-bottom: 2px; }
.m-4 { margin: 4px; }
.mx-4 { margin-left: 4px; margin-right: 4px; }
.my-4 { margin-top: 4px; margin-bottom: 4px; }
.m-8 { margin: 8px; }
.mx-8 { margin-left: 8px; margin-right: 8px; }
.my-8 { margin-top: 8px; margin-bottom: 8px; }
.m-16 { margin: 16px; }
.mx-16 { margin-left: 16px; margin-right: 16px; }
.my-16 { margin-top: 16px; margin-bottom: 16px; }
.m-24 { margin: 24px; }
.mx-24 { margin-left: 24px; margin-right: 24px; }
.my-24 { margin-top: 24px; margin-bottom: 24px; }
.m-48 { margin: 48px; }
.mx-48 { margin-left: 48px; margin-right: 48px; }
.my-48 { margin-top: 48px; margin-bottom: 48px; }

// padding helpers
.p-2 { padding: 2px; }
.px-2 { padding-left: 2px; padding-right: 2px; }
.py-2 { padding-top: 2px; padding-bottom: 2px; }
.p-4 { padding: 4px; }
.px-4 { padding-left: 4px; padding-right: 4px; }
.py-4 { padding-top: 4px; padding-bottom: 4px; }
.p-8 { padding: 8px; }
.px-8 { padding-left: 8px; padding-right: 8px; }
.py-8 { padding-top: 8px; padding-bottom: 8px; }
.p-12 { padding: 12px; }
.px-12 { padding-left: 12px; padding-right: 12px; }
.py-12 { padding-top: 12px; padding-bottom: 12px; }
.p-16 { padding: 16px; }
.px-16 { padding-left: 16px; padding-right: 16px; }
.py-16 { padding-top: 16px; padding-bottom: 16px; }
.p-24 { padding: 24px; }
.px-24 { padding-left: 24px; padding-right: 24px; }
.py-24 { padding-top: 24px; padding-bottom: 24px; }
.p-48 { padding: 48px; }
.px-48 { padding-left: 48px; padding-right: 48px; }
.py-48 { padding-top: 48px; padding-bottom: 48px; }

// width helpers
.w-auto { width: auto; }
.w-20p { width: 20%; }
.w-25p { width: 25%; }
.w-33p { width: 33%; }
.w-40p { width: 40%; }
.w-50p { width: 50%; }
.w-60p { width: 60%; }
.w-66p { width: 66%; }
.w-75p { width: 75%; }
.w-80p { width: 80%; }
.w-100p { width: 100%; }

.w-spacer { width: 6px; }

.w-20 { width: 20px; }
.w-40 { width: 40px; }
.w-60 { width: 60px; }
.w-80 { width: 80px; }
.w-100 { width: 100px; }
.w-120 { width: 120px; }
.w-140 { width: 140px; }
.w-160 { width: 160px; }
.w-180 { width: 180px; }
.w-200 { width: 200px; }
.w-300 { width: 300px; }
.w-400 { width: 400px; }

// height helpers
.h-spacer { height: 4px; }

.h-12 { height: 12px; }
.h-14 { height: 14px; }
.h-16 { height: 16px; }
.h-18 { height: 18px; }
.h-20 { height: 20px; }
.h-40 { height: 40px; }
.h-60 { height: 60px; }
.h-80 { height: 80px; }
.h-100 { height: 100px; }
.h-120 { height: 120px; }
.h-140 { height: 140px; }
.h-160 { height: 160px; }
.h-180 { height: 180px; }
.h-200 { height: 200px; }
.h-300 { height: 300px; }
.h-400 { height: 400px; }

/* prettier-ignore-end */

// flex
.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
