$menuShadow: 5px 5px 20px 8px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
  0px 1px 5px 0px rgb(0 0 0 / 12%);

$shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
  0px 1px 5px 0px rgb(0 0 0 / 12%);

$lineHeight: 40px;

// neo-table-container
.neo-table-container {
  @extend .neo-scrollbar;
  position: relative;

  .table-container {
    position: relative;
  }

  .d-none {
    display: none;
  }

  .header-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    margin-bottom: 10px;

    .left-container {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      column-gap: 10px;

      .header-search-container {
        .mat-form-field {
          width: 220px;
        }
      }

      .date-range-container {
        line-height: $lineHeight;
        margin-top: -1.8px;
        margin-left: 15px;

        &.mobile-view {
          margin-left: unset;
        }

        .datePicker-outer-container {
          margin-top: 5px !important;

          .datePicker-inner-container {
            &.errored {
              border-color: $warn-color !important;
            }

            padding: 4px 0px 0px 6px !important;
            border-radius: 5px !important;
            border-color: $date-range-border-color;
          }
        }
      }
    }

    .header-buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex-wrap: wrap;
      column-gap: 10px;

      .header-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        border-radius: 4px;

        &:not([ng-reflect-disabled="true"]):hover {
          opacity: 0.8;
        }
      }

      .flyout-filter {
        margin-left: 0;
      }
    }
  }

  /* START: classes for [fixedLayout]="false" */
  &:not(.very-small-screen) .dynamic-layout,
  :not(.mobile-view).dynamic-layout {
    overflow: auto;
    height: 60vh;
  }

  &:not(.very-small-screen) .neo-card-table-view,
  :not(.mobile-view).neo-card-table-view {
    overflow-x: auto;
    max-height: 57vh;

    .main-table {
      padding: 8px 8px 0 8px;
    }
  }

  .full-height {
    overflow: auto;
    height: calc(100vh - 175px);
  }

  /* END: classes for [fixedLayout]="false" */

  .main-table {
    width: 100%;
    margin-top: 10px;

    .mat-form-field {
      min-width: 95%;
      max-width: 100%;
      margin-right: 10px;
    }
  }

  .center {
    text-align: center;
    display: flex;
    justify-content: center;

    &.mat-header-cell {
      margin-left: 10px;
    }

    .mat-sort-header-content {
      text-align: center;
    }
  }

  .mat-sort-header-content {
    text-align: left;
  }

  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    border-bottom-style: none;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  .mat-row,
  .mat-footer-row {
    min-height: 38px;
  }
  table {
    border-collapse: collapse;
  }
  td.mat-cell {
    /* row padding */
    padding: 20px 0;
    border: none;
  }

  tr.mat-row {
    /* row border */
    border: 1px solid rgba(145, 158, 171, 0.2);
  }

  tr.mat-row,
  tr.mat-header-row,
  tr.mat-footer-row {
    border-bottom-style: none;
  }

  .mat-row,
  tr.mat-row {
    &.stripped-row {
      background-color: $mild-grey-color;
    }

    &.highligh-on-hover:hover {
      background-color: $aggregated-row-bg-color !important;
    }
  }

  th {
    text-transform: capitalize;
    color: #637381;
    &:first-of-type {
      padding-left: 24px !important;
    }

    &:last-of-type {
      padding-right: 24px !important;
    }
  }

  td {
    overflow: visible !important;
    line-break: anywhere;
    padding: 0px 10px 5px 0px !important;

    &:first-of-type {
      padding-left: 24px !important;
    }

    &:last-of-type {
      padding-right: 24px !important;
    }

    a {
      &:link,
      &:visited {
        text-decoration: none;
        color: $primary-dark-color;
      }

      &.errored {
        color: $warn-color;
      }
    }
  }

  .text-align-center {
    text-align: center;

    .mat-sort-header-container {
      display: flex;
      justify-content: center;

      &:not(.mat-sort-header-sorted) {
        padding-left: 18px;
      }
    }
  }

  .action-button {
    text-align: start;
    padding-left: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    .mat-icon {
      display: inline;
      color: $primary-color;
    }

    &.disabled {
      .mat-icon {
        color: $primary-lighter-color;
      }

      &:hover {
        transform: unset;
        opacity: unset;
        cursor: default;
      }
    }

    &:hover {
      transform: scale(1.125);
      opacity: 0.8;
    }
  }

  .mat-paginator-sticky {
    bottom: 0px !important;
    position: sticky !important;
    z-index: 10;
  }

  /* START: Responsive material table css to make it card for mobile view*/
  .mobile-view-header {
    display: none;
  }

  .sort-by-opener {
    display: none;
  }

  &.very-small-screen {
    #neoFlyoutFilterOpener {
      padding: 0 !important;
    }

    .mat-cell {
      justify-content: left !important;
      align-items: baseline !important;

      .mobile-view-header {
        line-break: normal;
      }

      .mat-button-toggle {
        width: 74px;
      }

      .neo-tri-state-toggle-button {
        width: 100%;

        .mat-button-toggle-group {
          min-width: 165px;
        }
      }
    }
  }

  // lt-md
  .mobile-view {
    &.main-table {
      border-spacing: 0 10px;
      padding-top: 0;
    }

    &.sort-by-opener {
      display: block;
      float: right;

      &:hover {
        transform: scale(1.125);
      }
    }

    .mat-header-row {
      display: none;
    }

    &.show-sort-by {
      .mat-header-row {
        display: flex;
        flex-direction: column;
        float: right;
        box-shadow: $menuShadow;
        height: fit-content;
        margin: 0 10px 0 0 !important;
        position: absolute;
        right: 45px;
        z-index: 101;
      }
    }

    .mat-form-field {
      min-width: initial;
      max-width: initial;
    }

    .mat-form-field-wrapper {
      padding-bottom: unset !important;
    }

    .mat-error {
      margin: 13px 0 12px;
    }

    .mat-row {
      display: flex;
      flex-direction: column;
      height: fit-content;
      align-items: start;
      margin-bottom: 10px;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.25rem;
      padding: 15px;
      border-radius: 1rem;
      background: content-box;
    }

    .mobile-view-header {
      display: flex !important;
      justify-content: space-between;
      align-items: baseline;
      text-transform: uppercase;
      padding: 0px 5px;
      min-width: fit-content;
      white-space: break-space;
      word-break: break-word;

      span {
        float: right !important;
      }
    }

    .mat-cell {
      display: flex;
      padding: 10px 5px !important;
      justify-content: left;
      align-items: center;
      max-width: 100% !important;
      width: 100%;

      &:first-of-type {
        padding-top: 20px !important;
        padding-left: 5px;
      }

      &:last-of-type {
        padding-bottom: 20px !important;
      }

      .mat-checkbox {
        padding: 0px 15px;
      }
    }

    tr.mat-row,
    tr.mat-header-row,
    tr.mat-footer-row {
      border-bottom-style: none;
      padding-left: 0;
      padding-right: 0;
    }

    th {
      &.mat-header-cell {
        display: none !important;

        &.mat-sort-header {
          display: flex !important;
          justify-content: center !important;
          width: 100%;
          padding: 10px !important;
          border: 1px solid lightgray;
          box-sizing: unset !important;
        }
      }
    }
  }

  /* END: Responsive material table css to make it card */

  .copyable-cell {
    display: flex;

    .ellipsifyableContent {
      display: inline-grid;
    }

    .content-copy-btn {
      display: none;
      cursor: pointer;
      margin-left: 5px;
      max-height: 24px;
      min-width: fit-content;

      &:active {
        transform: scale(0.9);
      }
    }

    &:hover {
      .content-copy-btn {
        display: block;
      }
    }
  }
}
