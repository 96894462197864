@import "variables";

//Customizing the focus color
.e-ddl.e-input-group.e-control-wrapper.e-input-focus::before,
.e-ddl.e-input-group.e-control-wrapper.e-input-focus::after {
  background: $primary-dark-color;
}

// multi select dropdowm
.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: $primary-dark-color;
  border-color: transparent;
  color: #fff;
}

//Customizing the appearance of pop-up element
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
  background: $primary-dark-color;
}

// time picker

//Customizing the focus color
.e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::before,
.e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::after,
.e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
.e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
.e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::before,
.e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::after,
.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after {
  background: $primary-dark-color;
}

//Customizing the TimePicker icon element
.e-time-wrapper .e-time-icon.e-icons,
*.e-control-wrapper.e-time-wrapper.e-time-icon.e-icons {
  color: $primary-dark-color;
}

// textbox

input.e-input::selection,
textarea.e-input::selection,
.e-input-group input.e-input::selection,
.e-input-group.e-control-wrapper input.e-input::selection,
.e-float-input input::selection,
.e-float-input.e-control-wrapper input::selection,
.e-input-group textarea.e-input::selection,
.e-input-group.e-control-wrapper textarea.e-input::selection,
.e-float-input textarea::selection,
.e-float-input.e-control-wrapper textarea::selection {
  background: $primary-dark-color;
  color: #fff;
}

.card-action-dropdown {
  border-radius: 1px;
  box-shadow: inset 0 0 5px grey;
}

/* Syncfusion components customizations: START */

/* Generic customizations: START */

/* ejs-tooltip */
.e-tooltip-wrap {
  &.dark-tooltip {
    background: $black-color !important;
    color: $white-color !important;
    border-radius: 4px;

    .e-arrow-tip-outer.e-tip-bottom,
    .e-arrow-tip-inner {
      color: $black-color;
      border-top-color: $black-color;
    }
  }

  &.v-scroll {
    overflow-y: scroll;
    max-height: 50vh;
  }
}

/* ejs-switch */
.e-switch-wrapper {
  &.e-switch-disabled {
    cursor: not-allowed;

    .e-switch-inner {
      .e-switch-off {
        opacity: 1;
      }
    }
  }
}

/* ejs-button */
.e-btn,
.e-css.e-btn {
  border-radius: 4px;
}

/* clickable */
.clickable {
  cursor: pointer;
}

/* ellipsify */
.neo-ellipsify {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Generic customizations: END */

/* Mixin for syncfusion styles */
@mixin syncfusion-components-styles($color, $lighter-color) {

  /* ejs-icons */
  &.e-icons,
  &.e-icons:not(.e-btn-icon) {
    color: $color;

    &:hover {
      &.clickable {
        border-radius: 50%;
        box-shadow: 0 0 5px 5px rgba($color, 0.15);
      }

      color: rgba($color, 0.90);
    }
  }

  /* ejs-textbox */
  &.e-float-input {

    label.e-float-text,
    input:focus~label.e-float-text,
    input:valid~label.e-float-text {
      color: $color;

      &:hover {
        color: rgba($color, 0.90);
      }
    }
  }

  /* ejs-switch */
  &.e-switch-wrapper,
  &.e-css.e-switch-wrapper {

    .e-switch-on,
    .e-switch-on {
      background-color: $lighter-color;

      &:hover {
        background-color: rgba($lighter-color, 0.90);
      }
    }

    &:hover .e-switch-inner.e-switch-active .e-switch-on {
      background-color: $lighter-color;

      &:hover {
        background-color: rgba($lighter-color, 0.90);
      }
    }


    .e-switch-handle.e-switch-active,
    &.e-switch-disabled .e-switch-handle.e-switch-active {
      background-color: $color;

      &:hover {
        background-color: rgba($color, 0.90);
      }
    }
  }

  /* ejs-button */
  &.e-btn,
  &.e-css.e-btn {
    background: $color;
    border-color: $color;
    color: $white-color;

    &:hover,
    &:focus {
      background-color: rgba($color, 0.90);
      border-color: rgba($color, 0.90);
      color: $white-color;
    }
  }
}

/* Primary */
.neo-primary {
  @include syncfusion-components-styles($primary-color, $primary-lighter-color);
}

/* Warn */
.neo-warn {
  @include syncfusion-components-styles($warn-color, $warn-lighter-color);
}

/* Syncfusion components customizations: END */
