$primary-mild-color: #57addf;
$primary-color: #334d99;
$primary-light-color: #667ab3;
$primary-lighter-color: #99a6cc;
$primary-lightest-color: #ebedf5;
$primary-dark-color: #002180;
$primary-darker-color: #00144d;

$accent-color: #00e5ba;
$accent-lighter-color: #99f5e3;
$accent-darker-color: #008970;

$warn-color: #b00020;
$warn-lighter-color: #ff607d;
$warn-lightest-color: #f0d2d2;
$warn-mild-color: #E58784;
$warn-dark-color: #B71D18;
$warn-light-color: #f51a1a;
$warn-darker-color: #9f001d;

$gray-border: #bbbbbb;
$gray-mild-color: #637381;
$darker-grey-text: #222222;
$dark-grey-text: #333333;
$medium-light-grey-text: #555555;
$medium-grey-text: #666666;
$gray-muted-color: #919EAB;
$primary-lightest-gray-color: #F9FAFB;
$light-blue-gray-color: #ebeef8;

$black-color: #000000;
$white-color: #ffffff;
$gray-color: #979797;
$gray-lightest-color: #F4F6F8;
$gray-medium-color: #D9D9D9;
$yellow-color: #ffff00;
$golden-yellow-color: #f59c1a;
$light-yellow-color: #ffe6a7;
$light-gray-color: #d2c3c3;
$lighter-gray-color: #ebeef8;
$mild-grey-color: #fafafa;
$steel-blue-color: #335c85;
$blue-mild-color: #006C9C;
$light-blue-color: #bcd5d7;
$light-sky-blue-color: #00B8D9;
$lighter-orange-color: #fef5e8;
$orange-mild-color: #FFD666;
$orange-color: #FFAB00;
$orange-dark-color: #B76E00;
$opaque-white-color: #ffffffc6;
$opaque-black-color: #00000078;
$purple-color: #7B61FF;

$progress-lighter-color: #fef5e8;
$progress-color: $golden-yellow-color;
$progress-darker-color: #f54f1a;

$success-light-color: #d2f0d7;
$success-mild-color: #77ED8B;
$success-milder-color: #22C55E;
$success-dark-color: #2ebe45;
$success-darker-color: #3abc5d;
$success-darkest-color: #118D57;

$employment-type-full-time: $light-blue-color;
$employment-type-part-time: #ffbaa7;
$employment-type-sub-contractor: #d7bdff;

$equipment-type-mobile: #7bdee5;
$equipment-type-static: #d1d1dd;

$content-bg-color: #f4f7fc;
$toggle-button-background-color: #d2d2d2;

$flyout-filter-backdrop-color: #5c5c5c6b;
$backdrop-color: #000000ed;

$tri-state-null-btn-selected-color: #dbdbdb;

$mat-body-1-font-size: 14px;
$mat-error-font-size: 10.5px;
$mat-disabled-border-color: #f0f0f0;
$mat-label-font-size: 11px;
$mat-ctrl-label-color: #00000099;
$mat-ctrl-border-color: #e0e0e0;
$mat-ctrl-hover-border-color: #212121;
$mat-error-letter-spacing: 1.5px;

$sub-item-list-bg-color: #99a6cc3d;
$aggregated-row-bg-color: #ebebeb;

$border-color: #dddddd;
$date-range-border-color: #e6e6e6;

$gray-color-300: #DFE3E8;
$gray-color-400: #C4CDD5;

$table-header-color: $gray-mild-color;
$table-header-background-color: #f8f9f9;

$text-color-primary: #212B36;
$text-color-secondary: #637381;
$text-color-disabled: #919EAB;

// Exported from Figma

// .[Core] Colors{
//   --Grey__100:#f9fafb;
//   --Grey__200:#f4f6f8;
//   --Grey__300:#dfe3e8;
//   --Grey__400:#c4cdd5;
//   --Grey__500:#919eab;
//   --Grey__600:#637381;
//   --Grey__700:#454f5b;
//   --Grey__800:#212b36;
//   --Grey__900:#161c24;
//   --Primary__100:#00071a;
//   --Primary__400:#00144d;
//   --Primary__500:#002180;
//   --Primary__700:#334d99;
//   --Primary__900:#ccd3e6;
//   --Secondary__100:#ccfaf1;
//   --Secondary__400:#99f5e3;
//   --Secondary__500:#00e5ba;
//   --Secondary__700:#005c4a;
//   --Secondary__900:#002e25;
//   --Info__100:#cafdf5;
//   --Info__400:#61f3f3;
//   --Info__500:#00b8d9;
//   --Info__700:#006c9c;
//   --Info__900:#003768;
//   --Success__100:#d3fcd2;
//   --Success__400:#77ed8b;
//   --Success__500:#22c55e;
//   --Success__700:#118d57;
//   --Success__900:#065e49;
//   --Warning__100:#fff5cc;
//   --Warning__400:#ffd666;
//   --Warning__500:#ffab00;
//   --Warning__700:#b76e00;
//   --Warning__900:#7a4100;
//   --Error__100:#ffd3d1;
//   --Error__400:#e58784;
//   --Error__500:#b71d18;
//   --Error__700:#971511;
//   --Error__900:#6f0814;

// .[Themes]{
// --Text__primary:var(--Grey/800);
// --Text__secondary:var(--Grey/600);
// --Text__disabled:var(--Grey/500);
// --Background__default:var(--Grey/100);
// --Background__paper:var(--Grey/white);
// --Background__neutral:var(--Grey/200);
// --Action__hover:var(--Grey/8%);
// --Action__selected:var(--Primary/12%);
// --Action__disabled:rgba(145, 158, 171, 0.8);
// --Action__disabledbg:var(--Grey/20%);
// --Action__focus:var(--Grey/24%);
// --Action__active:var(--Grey/600);
// --Border__outline:var(--Grey/20%);
// --Border__divider:rgba(145, 158, 171, 0.2);
// --Background__overlay:rgba(0, 0, 0, 0.7);
// --Text__inverted:var(--Grey/100);
// --Background__accent:var(--Primary/500);