@import 'neo_scrollbar';

$lineHeight: 48px;
$hoverBackground: rgba(0, 0, 0, 0.04);

.neo-flyout-filter-backdrop {
  background: $flyout-filter-backdrop-color;
}

neo-flyout-filter {
  .mat-icon {
    margin-left: 5px;
  }
}

.neo-flyout-filter-container {
  border-radius: 12px;

  .arrow {
    position: absolute;
    margin-top: -18px;
    margin-left: 75px;
    display: inline-block;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    border-bottom: 10px solid #ffffff;
  }

  .filter-options {
    max-height: calc(35px * 5);
    padding: 0px 5px;
    overflow-x: hidden;
    overflow-y: auto;

    .option{
      &:hover {
        background: $hoverBackground;
      }
    }

    .mat-checkbox-layout {
      white-space: normal !important;
      word-break: break-word;
    }
  }

  .filter-without-child-container-checkbox {
    line-height: $lineHeight;

    .filter-without-child-container-title {
      display: flex;
      justify-content: space-between;
      padding: 0px 19px 0px 24px;
      font-family: Inter;
      font-size: 15px;

      .mat-checkbox {
        &.mat-checkbox-label-before {
          width: 100%;

          .mat-checkbox-layout {
            width: 100%;
            justify-content: space-between;

            .mat-checkbox-inner-container {
              margin-left: unset;
              margin-right: 0px;
            }
          }
        }
      }
    }
  }

  div.filter-without-child-container-checkbox:hover {
    background: $hoverBackground;
  }

  .filter-without-child-container-toggle {
    line-height: $lineHeight;
    display: flex;
    padding: 0px 19px 0px 24px;

    span {
      @extend .mat-expansion-panel-header;
    }

    justify-content: space-between;
  }

  div.filter-without-child-container-toggle:hover {
    background: $hoverBackground;
  }

  .filter-without-child-container-datePicker {
    line-height: $lineHeight;
    display: grid;
    padding: 0px 19px 0px 24px;

    span {
      @extend .mat-expansion-panel-header;
    }

    justify-content: space-between;
  }

  div.filter-without-child-container-datePicker:hover {
    background: $hoverBackground;
  }

  &.mat-menu-panel {
    min-width: 240px;

    &.mobile-view {
      max-width: 98%;
      margin: auto 10px;
    }
  }

  .mat-expansion-panel {
    border-radius: 0px !important;
    box-shadow: none !important;

    .select-all-container {
      margin: auto 5px;
    }
  }

  .mat-expansion-panel-header.mat-expanded {
    height: 40px;
  }

  .mat-expansion-panel-body {
    padding: 13px 24px 16px !important;
    display: flex;
    border: 1px solid #e6e0e0de;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 14px;
    margin: 7px;
    line-height: 35px;
  }

  @extend .neo-scrollbar;
}
