@use "@angular/cdk/overlay-prebuilt.css" as cdkOverlay;

@use "variables" as neoVars;
@use "neo_mat_theme" as neoMatTheme;
@use "form" as neoForm;
@use "ng-select" as ngSelect;
@use "neo_typography" as neoNeoTypography;
@use "neo_scrollbar" as neoScrollbar;
@use "neo_sidebar_scrollbar" as neoSidebarScrollbar;
@use "neo_sidemenu_scrollbar" as neoSidemenuScrollbar;
@use "footer_action_button" as footerActionButton;
@use "neo_flyout_filter" as neoFlyoutFilter;
@use "neo_table" as neoTable;
@use "neo_reporting" as neoReporting;
@use "neo_form_renderer" as neoFormRenderer;
@use "neo_calendar_v2" as neoCalendar;

@include neoVars.neo-colors;

html,
body {
  @extend .neo-scrollbar;

  height: 100%;
  margin: 0;
}

.work-perform-modal {
  mat-dialog-container {
    padding: 0;
    border-radius: 10px;
  }
}

.neo-alert-panel {
  padding: 0px !important;
  min-height: 0px !important;
  margin-top: 50px !important;
}


#reportContainer {
  height: 800px;
}

.no-data-found {
  width: 100%;
  display: flex;
  padding: 10px 0px;
  padding-top: 32px;
  column-gap: 5px;
  text-align: center;
  color: neoVars.$gray-500;
  align-items: center;
  justify-content: center;

  &.align-center {
    height: 50%;
  }
}

.position-fixed {
  position: fixed !important;
}

.debug {
  color: white;
  background-color: black;
  padding: 10px;
  z-index: 1000;
}
